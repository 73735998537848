// © Microsoft Corporation. All rights reserved.

import {
    WebWorkerMessageId,
    environment,
    initializeMessageHandler,
    loadConfigTree,
    perfMarker,
    registerCommandMessageHandler,
    registerConfigResolverTasks,
    registerPdpTasks
} from "@msnews/web-worker";

(() => {
    // since we can't use perfMarker until after environment is initialized,
    // capture the raw time offset at start now, so we can use it after init.
    const startTime = performance.now();

    // initialize environment variables
    environment.init();

    // record with the custom time we saved earlier once environment has been fully initialized.
    perfMarker("Start", startTime);

    // add message listener first to avoid missing messages from main thread
    initializeMessageHandler();

    // register tasks dependent on/should wait for other tasks here
    registerConfigResolverTasks();
    registerCommandMessageHandler(WebWorkerMessageId.UpdateMyFeed, () => { /* noop */ }); // register command to avoid triggering apperrors while windows feed tasks registration is disabled
    registerCommandMessageHandler(WebWorkerMessageId.PostTtvr, () => { /* noop */ });
    registerCommandMessageHandler(WebWorkerMessageId.Updatepdp, () => { /* noop */ });
    registerCommandMessageHandler(WebWorkerMessageId.UpdateMyBriefing, () => { /* noop */ });
    registerCommandMessageHandler(WebWorkerMessageId.AuthHeaders, () => { /* noop */ });

    // Register WPO call feed tasks
    registerPdpTasks();

    // start independent tasks here
    loadConfigTree();
})();